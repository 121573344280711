import { atom } from 'recoil';

export const atomUserRoles = atom({
  key: "atomUserRoles",
  default: []
})

export const atomCustomerID = atom({
  key: "atomCustomerID",
  default: ""
})

export const atomAllServices = atom({
    key: "atomAllServices",
    default: []
  })

export const atomMainServicesArray = atom({
    key: "atomMainServicesArray",
    default: []
  })

export const atomCurrentUser = atom({
  key: "atomCurrentUser",
  default: ""
})

export const atomValidationSidebarExpanded = atom({
  key: "atomValidationSidebarExpanded",
  default: true
})

export const atomDocumentDetailsPaneWidth = atom({
  key: "atomDocumentDetailsPaneWidth",
  default: [100, 0]
})

export const atomDocumentDetailsPaneMinWidth = atom({
  key: "atomDocumentDetailsPaneMinWidth",
  default: 0
})

export const atomSnackbarIsOpen = atom({
  key: "atomSnackbarIsOpen",
  default: false
})

export const atomSnackbarSeverity = atom({
  key: "atomSnackbarSeverity",
  default: "info"
})

export const atomSnackbarMessage = atom({
  key: "atomSnackbarMessage",
  default: ""
})

export const atomRefreshParent = atom({
  key: "atomRefreshParent",
  default: 0
})

export const atomRefreshDocument = atom({
  key: "atomRefreshDocument",
  default: 0
})

export const atomBackDropIsOpen = atom({
  key: "atomBackDropIsOpen",
  default: false
})

export const atomShowOrgXmlPopOut = atom({
  key: "atomShowOrgXmlPopOut",
  default: false
})

export const atomShowEmlPopOut = atom({
  key: "atomShowEmlPopOut",
  default: false
})

export const atomShowPdfPopOut = atom({
  key: "atomShowPdfPopOut",
  default: false
})

export const atomPdfDetails = atom({
  key: "atomPdfDetails",
  default: {}
})

export const atomDeleteReceivedMessage = atom({
  key: "atomDeleteReceivedMessage",
  default: false
})

export const atomDeleteReceivedMessageArray = atom({
  key: "atomDeleteReceivedMessageArray",
  default: []
})

export const atomExceptionCount = atom({
  key: "atomExceptionCount",
  default: {}
})

export const atomDocumentsObject = atom({
  key: "atomDocumentsObject",
  default: {}
})

export const atomXMLTestInput = atom ({
  key: "atomXMLTestInput",
  default: ""
})

export const atomJSONTestOutput = atom ({
  key: "atomJSONTestOutput",
  default: ""
})

export const atomXMLTestOutput = atom ({
  key: "atomXMLTestOutput",
  default: ""
})

export const atomJSONTestInput = atom ({
  key: "atomJSONTestInput",
  default: ""
})

export const atomCustomerIDTestInput = atom ({
  key: "atomCustomerIDTestInput",
  default: ""
})

export const atomDocumentConfiguration = atom({
  key: "atomDocumentConfiguration",
  default: {}
})

export const atomDocumentType = atom({
  key: "atomDocumentType",
  default: "Invoice"
})

export const atomReports = atom({
  key: "atomReports",
  default: "[]"
})

export const atomIvsSources = atom({
  key: "atomIvsSources",
  default: "[]"
})